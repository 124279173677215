import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/Users/kenneth/Repos/typebrigade/typebrigade.com/src/components/TemplateBlogPost.jsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><a parentName="p" {...{
        "href": "http://www.meetup.com/typebrigade/events/222545955/"
      }}>{`Type Brigade №26`}</a>{` is tomorrow, `}<strong parentName="p">{`now at Hootsuite HQ`}</strong>{` instead of Mobify HQ!`}</p>
    <p>{`Sorry for the short notice, but some minor flooding at Mobify necessitated the move. Thanks so much to our friends at Hootsuite for letting us use their space instead.`}</p>
    <address>
Hootsuite HQ<br />
5 E 8th Ave,<br />
Vancouver, BC
    </address>
    <p>{`If you have any questions about the change, feel free to `}<a parentName="p" {...{
        "href": "mailto:kenneth@typebrigade.com"
      }}>{`email me`}</a>{` or `}<a parentName="p" {...{
        "href": "https://twitter.com/typebrigade"
      }}>{`mention us on Twitter`}</a>{`.`}</p>
    <figure className="figure--breakout">
      <div className="figure--embed">
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d650.9163269429183!2d-123.10435050429878!3d49.263789368902366!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0000000000000000%3A0x03d6d0a44ca262f2!2sHootSuite+Media+Inc.!5e0!3m2!1sen!2s!4v1433358143466" width="600" height="450" frameBorder="0" style={{
          "border": "0"
        }}></iframe>
      </div>
      <figcaption>
Type Brigade №26’s new location.
      </figcaption>
    </figure>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      